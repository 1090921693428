import { Controller } from "@hotwired/stimulus";
export default class extends Controller {

  connect() {
  }

  validateFirstAndLastName(e){
    const firstNameEl = document.getElementsByClassName('input-firstname')[0];
    const lastNameEl = document.getElementsByClassName('input-lastname')[0];
    const submitButton = document.getElementsByClassName('btn-submit')[0];
    if(firstNameEl.value.length > 0 && lastNameEl.value.length > 0){
      submitButton.disabled = false;
    }else{
      submitButton.disabled = true;
    }

    this._validateFirstName(firstNameEl);
    this._validateLastName(lastNameEl);
  }

  _validateFirstName(firstNameEl){
    if(firstNameEl.value.length > 0){
      firstNameEl.classList.remove('txt-invalid');
    }else{
      firstNameEl.classList.add('txt-invalid');
    }
  }

  _validateLastName(lastNameEl){
    if(lastNameEl.value.length > 0){
      lastNameEl.classList.remove('txt-invalid');
    }else{
      lastNameEl.classList.add('txt-invalid');
    }
  }
}
